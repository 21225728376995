import React, {useEffect, useState} from 'react';
import sanityClient from "../../../client";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
import {useScreen} from "../../../hooks/useScreen";
import {Break} from "../break/break";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source);}

export function SkillsetBlock() {
    const isDesktopResolution = useScreen("(min-width:900px)", true);
    const isMobileResolution = useScreen("(max-width:899px)", false);

    const [skillsetData, setSkillsetData] = useState(null);

    useEffect(() => {
        sanityClient.fetch(
            `*[_type == "SkillsetBlocks"]{
        title,
        textContent,
        image{
          asset->{
          _id,
          url
        }
      }
    }`
        )
            .then((data) => setSkillsetData(data))
            .catch(console.error);
    }, []);


    return (
        <>
            {isDesktopResolution &&
                <h1 style={{fontSize: "2em", textAlign: "center"}}>The Skillset You Get</h1>
            }
            {isMobileResolution &&
                <h1 style={{fontSize: "1.44em", textAlign: "center"}}>The Skillset You Get</h1>
            }
            <Break/>
            {isDesktopResolution &&
                <div style={{width: "100%", height: "auto", display: "flex", justifyContent: "space-evenly"}}>
                    {skillsetData && skillsetData.map((skillSet, index)=>(
                        <div style={{
                            width: "20%",
                            backgroundColor: "#151414",
                            border: "white 1.2px solid",
                            borderRadius: ".4em",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "2em 1em",
                            margin: ".5em"
                        }}
                             key={index}
                        >
                            <img src={urlFor(skillSet.image).url()} style={{height: "5em", width: "auto"}}/>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-end"}}>
                                <h1 style={{marginTop: "1em", textAlign: "center"}}>{skillSet.title}</h1>
                                <BlockContent blocks={skillSet.textContent} style={{fontSize: "1em"}}/>
                            </div>
                        </div>
                    ))}

                </div>
            }
            {isMobileResolution &&
                <div style={{width: "100%", flex: "50%", height: "auto", display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "center"}}>
                    {skillsetData && skillsetData.map((skillSet, index)=>(
                        <div style={{
                            width: "35%",
                            backgroundColor: "#121010",
                            border: "white 1.2px solid",
                            borderRadius: ".4em",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "1.5em 1em",
                            margin: ".25em",
                            justifyContent: "space-evenly"
                        }}
                             key={index}
                        >
                            <div style={{display: "flex",
                                flexDirection: "column",
                                alignItems: "center",}}>
                            <img src={urlFor(skillSet.image).url()} style={{height: "5em", width: "auto"}}/>
                            <h1 style={{marginTop: "1em", textAlign: "center"}}>{skillSet.title}</h1>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-end"}}>
                                <div style={{fontSize: "1em", textAlign: "center"}}>
                                <BlockContent blocks={skillSet.textContent}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
        </>
    );
}