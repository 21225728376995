import React from 'react';
import "./theme.scss";
import {useScreen} from "../hooks/useScreen";

export function Theme({children}) {
    const isDesktopResolution = useScreen("(min-width:900px)", true);
    const isMobileResolution = useScreen("(max-width:899px)", false);

    return(
        <div className={"wrapper"} >
            {isDesktopResolution &&
                <div style={{
                    marginRight: "15vw",
                    marginLeft: "15vw",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>{children}</div>
            }
            {isMobileResolution &&
                <div style={{
                    marginRight: "5vw",
                    marginLeft: "5vw",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>{children}</div>
            }

        </div>
    );
}