import React,{useEffect, useState} from 'react';
import sanityClient from "../../../../../client";
import BlockContent from "@sanity/block-content-to-react";
import {useScreen} from "../../../../../hooks/useScreen";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source);
}

export function AboutCarouselItem5() {
    const isDesktopResolution = useScreen("(min-width:900px)", true);
    const isMobileResolution = useScreen("(max-width:899px)", false);


    const [aboutData5, setAboutData5] = useState(null);

    useEffect(() => {
        sanityClient.fetch(
            `*[_type == "about5"]{
        title,
        textContent,
        image{
          asset->{
          _id,
          url
        }
        }
    }`
        )
            .then((data) => setAboutData5(data))
            .catch(console.error);
    }, []);

    return(
        <>
            {isDesktopResolution && <>{aboutData5 && aboutData5.map((item, index)=>(
                <div style={{
                    width: "77%",
                    height: "30em",
                    backgroundColor: "#262422",
                    border: "white 1.2px solid",
                    borderRadius: ".4em",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "2em 1em",
                    margin: ".15em",
                }}
                     key={index}
                >
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <img style={{
                            width: "100%",
                            marginTop: "1.1em" ,
                            borderRadius: ".4em",
                            height: "10em",
                            objectFit: "cover"
                        }}
                             src={urlFor(item.image).url()} />
                        <div style={{height: "4.6em", marginTop: ".25em"}}>
                            <h1 style={{marginTop: ".75em", textAlign: "center"}}>{item.title}</h1>
                        </div>
                        <div style={{
                            fontSize: "1em",
                            display: "flex",
                            alignItems: "flex-end",
                            width: "auto",
                            padding: "0 .5em",
                            textAlign: "center",
                            marginTop:"1em"
                        }}>
                            <BlockContent blocks={item.textContent}/>
                        </div>

                    </div>
                </div>
            ))}</>}
            {isMobileResolution && <>
                {aboutData5 && aboutData5.map((item, index) => (
                    <div style={{
                        width: "77%",
                        height: "32em",
                        backgroundColor: "#262422",
                        border: "white 1.2px solid",
                        borderRadius: ".4em",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "2em 1em",
                        margin: ".15em",
                    }}
                         key={index}
                    >
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <img style={{
                                width: "100%",
                                marginTop: "1.1em" ,
                                borderRadius: ".4em",
                                height: "10em",
                                objectFit: "cover"
                            }}
                                 src={urlFor(item.image).url()} />
                            <div style={{height: "4.6em", marginTop: ".25em"}}>
                                <h1 style={{marginTop: ".25em", textAlign: "center"}}>{item.title}</h1>
                            </div>
                            <div style={{
                                fontSize: "1em",
                                display: "flex",
                                alignItems: "flex-end",
                                width: "auto",
                                padding: "0 .5em",
                                textAlign: "center"
                            }}>
                                <BlockContent blocks={item.textContent}/>
                            </div>

                        </div>
                    </div>
                ))}
            </>
            }
        </>
    );
}