import React from 'react';
import {HeaderListItems} from "./headerListItems/headerListItems";
import {Link} from "react-router-dom";

export function HeaderList() {
    return(
        <div style={{display: "flex", justifyContent: "center"}}>
            {HeaderListItems.map((item, id)=> (
                <Link key={id}
                   to={item.link}
                   style={{paddingLeft: "2em", paddingRight: "2em", textDecoration: "none", color: "#121010"}}
                >{item.name}</Link>
            ))}
        </div>
    );
}