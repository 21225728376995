import React from 'react';
import {IoIosArrowBack} from "react-icons/io";

export function BackArrow(props) {
    const { className, style, onClick } = props;
    return(
        <div onClick={onClick} className={`arrow ${className}`} >
            <IoIosArrowBack class="arrows" style={{color:"white"}}/>
        </div>
    );
}