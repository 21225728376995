import React, {useRef, useEffect, useState} from 'react';
import {useFadeInOnScroll} from "../../../hooks/useFadeInOnScroll";
import {useScreen} from "../../../hooks/useScreen";
import {Link} from "react-router-dom";
import mT from "../../../assets/media/dsa.webp"

import thumbnail from "../../../assets/media/thumbnail.jpg"
import thumbnailA from "../../../assets/media/thumbnailA.jpg"
import thumbnailB from "../../../assets/media/thumbnailB.jpg"
import thumbnailC from "../../../assets/media/thumbnailC.jpg"
import thumbnailD from "../../../assets/media/thumbnailD.jpg"
import thumbnail2 from "../../../assets/media/thumbnail2.jpg"
import ReactPlayer from "react-player";
import sanityClient from "../../../client";
import imageUrlBuilder from "@sanity/image-url";
import {Break} from "../break/break";
import ReactGA from "react-ga4";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source);
}



export function Video() {

    const isDesktopResolution = useScreen("(min-width:900px)", true);
    const isMobileResolution = useScreen("(max-width:899px)", false);

    const videoRef = useRef(null);
    useFadeInOnScroll(videoRef);

    const [videoData, setVideoData] = useState(null);

    useEffect(() => {
        sanityClient.fetch(
            `*[_type == "video"]{
        title,
        textContent,
        image{
          asset->{
          _id,
          url
        }
      }
    }`
        )
            .then((data) => setVideoData(data))
            .catch(console.error);
    }, []);

    const handleClick = (platform) => {
        ReactGA.event({
            category: 'Booking Link',
            action: 'Click',
            label: platform,
        });
    };

    return(
        <div style={{width: "100%", display: "flex", flexDirection:"column", alignItems:"center"}}>
            {isDesktopResolution &&
                <>
                    {videoData && videoData.map((item, index)=>(
                        <div style={{ width: "100%", height: "auto", objectFit: "cover" ,border: "white 1.2px solid" }}>
                            <ReactPlayer
                                url={item.title}
                                controls
                                light={urlFor(item.image).url()}
                                height={"30em"}
                                width={"100%"}
                            />
                        </div>
                    ))}

                    <br/>
                    <Link to={"/booking"} onClick={()=>handleClick("/booking")}><button className={"btn"}>Book A Free Strategy Call</button></Link>
                </>
            }
            {isMobileResolution &&
                <>
                    <div style={{textAlign: "center"}}>
                    <h1>1-on-1 Online Dating Coach<br/></h1>
                    <h2 style={{color: "#FAC91C", fontSize: "2em"}}>RICHARD JANSON</h2>
                    <p>I help you become the most charismatic and attractive version of yourself.</p>
                    <Break/>
                    </div>
                {videoData && videoData.map((item, index)=>(
                    <div style={{ width: "90vw", objectFit: "scale-down", backgroundColor: "#393939", border: "white 1.2px solid",}}>
                        <ReactPlayer
                            url={item.title}
                            controls
                            light={mT}
                            height={"25em"}
                            width={"100%"}
                        />
                    </div>
                ))}
                    <br/>
                    <Break/>
                    <Link to={"/booking"} onClick={()=>handleClick("/booking")}><button className={"btn"} style={{fontSize: "1.3em"}}>Book A Free Strategy Call</button></Link>
                </>
            }

        </div>
    );
}