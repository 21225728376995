import React from 'react';
import {IoIosArrowForward} from "react-icons/io";

export function NextArrow(props) {
    const { className, style, onClick } = props;
    return(
        <div onClick={onClick} className={`arrow ${className}`} >
            <IoIosArrowForward class="arrows" style={{color:"white"}}/>
        </div>
    );
}