export const HeaderListItems = [
    {
        id: 1,
        name: "Home",
        link: "/",
    },
    {
        id: 2,
        name: "Book A Call",
        link: "/booking",
    },
    {
        id: 3,
        name: "About Us",
        link: "/about",
    },
    {
        id: 4,
        name: "FAQ",
        link: "/faq",
    },
]