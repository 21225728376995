import React, {useEffect, useState}  from 'react';
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {NextArrow} from "../about/aboutCarousel/aboutCarouselArrows/nextArrow";
import {BackArrow} from "../about/aboutCarousel/aboutCarouselArrows/backArrow";
import {SliderItem1} from "./sliderItems/sliderItem1";
import {SliderItem2} from "./sliderItems/sliderItem2";
import {SliderItem3} from "./sliderItems/sliderItem3";
import {SliderItem4} from "./sliderItems/sliderItem4";
import {SliderItem5} from "./sliderItems/sliderItem5";
import {SliderItem6} from "./sliderItems/sliderItem6";


export function TestimonialSlider() {


    const settings = {
        dots: false,
        infinite: true,
        centerMode: true,
        centerPadding: "-1px",
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        nextArrow: <NextArrow to={"next"} />,
        prevArrow: <BackArrow to={"prev"}/>,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 899,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return(
        <div style={{width: "100%", height: "auto"}}>
            <Slider {...settings}>
                <SliderItem1/>
                <SliderItem2/>
                <SliderItem3/>
                <SliderItem4/>
                <SliderItem5/>
                <SliderItem6/>
            </Slider>
        </div>
    );
}