import React, {useState} from 'react';
import ReactGA from "react-ga4";
import {Break} from "../../../components/sections/break/break";
import {Header} from "../../../components/header/header";
import {Link} from "react-router-dom";
import {useScreen} from "../../../hooks/useScreen";
import {TestimonialSlider} from "../../../components/sections/testimonial/testimonialSlider";

ReactGA.send({ hitType: "pageview", page: "/booking/confirmed", title: "Confirmed Booking" });
ReactGA.event({
    category: "Booking Event",
    action: "Booking Confirmed"
})

export function ConfirmedBooking() {
    const isDesktopResolution = useScreen("(min-width:900px)", true);
    const isMobileResolution = useScreen("(max-width:899px)", false);

    const [isOpen, setIsOpen] = useState(false);

    function toggle() {
        setIsOpen((isOpen) => !isOpen);
    }


    return(
        <>
            <Header/>
        <div style={{display: "flex",justifyContent: "center", flexDirection: "column", alignContent: "center", alignItems: "center"}}>
            <Break/>
            <Break/>
            {isDesktopResolution &&
                <div className={"textBoxConfirm"} style={{textAlign: "center", padding: "4em", width: "20em"}}>
                    <h1>Thank You!</h1>
                    <p>We have received your application for the strategy call. If you have any questions contact us at
                        richard@jansoncoaching.com
                    </p>
                    <h2>See you in the call!</h2>
                    <button onClick={toggle} className={"btn"} style={{width: "20em", margin:"1.5em 0", alignItems: "center"}}>Read Client Testimonials</button>
                </div>

            }
            {isMobileResolution &&
                <div className={"textBoxConfirm"} style={{textAlign: "center", padding: "4em", width: "90vw"}}>
                    <h1>Thank You!</h1>
                    <p>We have received your application for the strategy call. If you have any questions contact us at
                        richard@jansoncoaching.com
                    </p>
                    <h2>See you in the call!</h2>
                    <button onClick={toggle} className={"btn"}
                            style={{width: "20em", margin: "1.5em 0", alignItems: "center"}}>Read Client Testimonials
                    </button>
                </div>
            }
            {isOpen &&<>
                <Break/>
                <div style={{width: "100vw", background: "#262422", display: "flex", justifyContent: "center", height: "auto"}}>
                    <div style={{width: "70%"}}>
                        <Break/>
                        <TestimonialSlider/>
                        <Break/>
                    </div>

                </div>
            </>
            }
        </div>
        </>
    );
}