import React, {useEffect, useState} from "react";
import {Break} from "../../components/sections/break/break";
import { Widget } from '@typeform/embed-react'
import {useScreen} from "../../hooks/useScreen";
import bgBooking from "../../assets/media/bgBooking.png"
import {Header} from "../../components/header/header";
import ReactGA from "react-ga4";
import { InlineWidget } from "react-calendly";


ReactGA.send({ hitType: "pageview", page: "/booking", title: "Booking" });



/* <Widget id="rAN76fEe" style={{zIndex: "10" ,width: '80%', minHeight: "30em", height: "auto"}} className="my-form" />
*  <Widget id="rAN76fEe" style={{ width: '100%', minHeight: "30em", height: "auto"}} className="my-form" />
* */


export function BookingPage() {
    const isDesktopResolution = useScreen("(min-width:900px)", true);
    const isMobileResolution = useScreen("(max-width:899px)", false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [isOpen, setIsOpen] = useState(true);

    function toggle() {
        setIsOpen((isOpen) => !isOpen);
    }

    const handleClick = (platform) => {
        ReactGA.event({
            category: 'Booking Link',
            action: 'Click',
            label: platform,
        });
    };


    return(
        <>
            <Header/>
            <Break/>
            <Break/>
            {isDesktopResolution &&
                <>
                    {isOpen && <div style={{position: "absolute", top: "7em", display: "flex", justifyContent: "center", zIndex: "100" ,width: '80%', minHeight: "34em", height: "auto",}}>
                        <div style={{position: "absolute", top: "6vw", width: "40%", textAlign: "center", zIndex: "90"}}>
                            <h1 style={{fontSize: "2em"}}>Apply for a Free Strategy Call!</h1>
                            <p>Ready to transform your love life? Apply now for a free strategy call!</p>

                            <p>During this consultation, we'll tackle your current situation, roadblocks, needs and goals.</p>

                            <p style={{color: " #FAC91C"}}>We will figure out which solution is the best for your needs and if we are a good match to work together, you will get a step-by-step plan for working together.</p>

                            <p>   Please note that my services are in high demand so free strategy calls are reserved for men who are genuinely motivated and prepared to take action toward achieving their goals.

                                If that is you then I’m excited to help!
                            </p>
                            <span onClick={()=>handleClick("calendly")}>
                            <button className={"btn"} style={{width: "14em", cursor: "pointer"}} onClick={toggle}>Let’s Get Started!</button>
                       </span>
                        </div>
                        <img src={bgBooking} className={"darkerImg"} style={{zIndex: "80" , borderRadius: ".8em", width: "70%", height: "auto", objectFit: "cover", }} />
                    </div>}
                    <div style={{width: "80%", zIndex: "10"}}>
                    <InlineWidget url={"https://calendly.com/d/ckg7-8zq-9m5?background_color=f9f9f1&primary_color=f8da71"} styles={{
                        width: "100%",
                        height: "32em",
                    }
                    }/>
                </div>
                </>
                    }
            {isMobileResolution && <>
                {isOpen && <div style={{position: "absolute", top: "7em", display: "flex", justifyContent: "center", zIndex: "100" ,width: '94%', minHeight: "32em", height: "auto",}}>
                    <div style={{position: "absolute", top: "6vw", width: "90%", textAlign: "center", zIndex: "90"}}>
                        <h1>Apply for a Free Strategy Call!</h1>
                        <p>Ready to transform your love life? Apply now for a free strategy call!</p>

                        <p>During this consultation, we'll tackle your current situation, roadblocks, needs and goals.</p>

                        <p style={{color: " #FAC91C"}}>We will figure out which solution is the best for your needs and if we are a good match to work together, you will get a step-by-step plan for working together.</p>

                        <p>   Please note that my services are in high demand so free strategy calls are reserved for men who are genuinely motivated and prepared to take action toward achieving their goals.

                            If that is you then I’m excited to help!
                        </p>
                        <span onClick={()=>handleClick("calendly")}>
                        <button className={"btn"} style={{width: "14em", cursor: "pointer"}} onClick={toggle}>Let’s Get Started!</button>
                        </span>
                    </div>
                    <img src={bgBooking} className={"darkerImg"} style={{zIndex: "80" , borderRadius: ".8em", width: "100%", height: "auto", objectFit: "cover", }} />
                </div>} <div style={{width: "80%"}}>
                <InlineWidget url={"https://calendly.com/d/ckg7-8zq-9m5?background_color=f9f9f1&primary_color=f8da71"} styles={{
                    width: "100%",
                    height: "30em"
                }
                }/>
            </div>
            </>}

        </>
    );
}