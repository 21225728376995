import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";

export function useFadeInOnScroll(ref, threshold = 0.15) {
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    gsap.fromTo(
                        entry.target,
                        { opacity: 0 },
                        { opacity: 1, duration: 5, ease: "power2.out" }
                    );
                    observer.unobserve(entry.target);
                }
            },
            { threshold }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref, threshold]);
}