import React, {useEffect, useState} from 'react';
import sanityClient from "../../../../client";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import {useScreen} from "../../../../hooks/useScreen";


const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source);
}


export function SliderItem1() {
    const isDesktopResolution = useScreen("(min-width:900px)", true);
    const isMobileResolution = useScreen("(max-width:899px)", false);

    const [quoteData, setQuoteData] = useState(null);

    useEffect(() => {
        sanityClient.fetch(
            `*[_type == "testimonial1"]{
        title,
        textContent,
        slug,
        button,
        image{
          asset->{
          _id,
          url
        }
      }
    }`
        )
            .then((data) => setQuoteData(data))
            .catch(console.error);
    }, []);

    return(
        <>
            {isDesktopResolution && <div>
                {quoteData && quoteData.map((item, index)=>(
                    <div style={{width: "98%", height: "24em" ,display: "flex", justifyContent: "space-between", flexDirection: "row"}} className={"slide"}>
                        <div key={index} style={{width: "50%", padding: "2em", display: "flex", justifyContent: "flex-start", flexDirection: "column", flexShrink: "0"}}>
                            <h5>{item.title}</h5>
                            <div style={{fontSize: "100%", fontStyle: "italic"}}>
                                <BlockContent blocks={item.textContent}/>
                            </div>

                        </div>
                        <div style={{width: "30vw", padding: "2em"}}>
                            <img src={urlFor(item.image).url()} style={{ borderRadius: ".4em", width: "auto", height: "100%"}} />
                        </div>
                    </div>
                ))}
            </div>
            }
            {isMobileResolution &&
                <div>
                    {quoteData && quoteData.map((item, index)=>(
                        <div style={{width: "90%", height: "45em" , fontSize: "1.1em" ,display: "flex", alignContent: "center" ,justifyContent: "space-between", flexDirection: "column", alignItems: "center"}} className={"slide"}>
                            <div key={index} style={{width: "90%", height: "auto", padding: "1em", display: "flex", justifyContent: "center", flexDirection: "column", flexShrink: "0", alignItems: "center" ,textAlign: "center"}}>
                                <h5>{item.title}</h5>
                                <div style={{width: "90%", padding: "1em 0 0em 0"}}>
                                    <img src={urlFor(item.image).url()} style={{ borderRadius: ".4em", width: "100%", height: "auto"}} />
                                </div>
                                <div style={{marginTop: ".15em", fontStyle: "italic"}}>
                                    <BlockContent blocks={item.textContent}/>
                                </div>

                            </div>
                        </div>
                    ))}
                </div>
            }
        </>
    );
}