import React from 'react';
import {Break} from "../../sections/break/break";
import {HeaderListItems} from "../headerList/headerListItems/headerListItems";
import {Link} from "react-router-dom";
import {ReactComponent as LogoB} from "../../../assets/icons/logoB.svg";

export function MobileHeader() {
    return(
        <div style={{backgroundColor: "#F9F9F1", width: "100vw", position: "fixed", left: "0", zIndex:"110"}}>
            <Break/>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", fontSize: "1.3em"}}>
                {HeaderListItems.map((item, id)=> (
                    <Link key={id}
                          to={item.link}
                          style={{paddingLeft: "2em", paddingRight: "2em", textDecoration: "none", color: "#121010", margin: ".5em 0"}}
                    >{item.name}</Link>
                ))}
            </div>
            <Break/>
            <Break/>
        </div>
    );
}