import React, {useRef, useEffect, useState} from 'react';
import "../sections.scss";
import {useFadeInOnScroll} from "../../../hooks/useFadeInOnScroll";
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "../../../client";
import {useScreen} from "../../../hooks/useScreen";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source);
}

export function Featured() {
    const isDesktopResolution = useScreen("(min-width:900px)", true);
    const isMobileResolution = useScreen("(max-width:899px)", false);

    const [featuredData, setFeaturedData] = useState(null);

    useEffect(() => {
        sanityClient.fetch(
            `*[_type == "featured"]{
            title,
            image{
          asset->{
          _id,
          url
        }
      }
    }`
        )
            .then((data) => setFeaturedData(data))
            .catch(console.error);
    }, []);

    const featuredRef = useRef(null);
    useFadeInOnScroll(featuredRef);
    return(
        <>
            {isDesktopResolution &&
                <div ref={featuredRef} style={{ display: "flex", flexDirection:"column", alignItems:"center"}}>
                    <div ref={featuredRef} className={"featuredList"} style={{height: "14em", display: "flex", flexDirection: "column", alignItems:"center", justifyContent: "center"}}>
                        <h1 style={{margin: "-1em 0 1em 0", fontSize: "2em", color: "black"}}>Featured On</h1>
                        <div style={{display: "flex", flexDirection: "row", alignItems:"center", justifyContent: "center"}}>
                        {featuredData && featuredData.map((featured, index) => (
                            <div key={index} >
                                <img src={urlFor(featured.image).url()}
                                     alt={featured.title}
                                     style={{padding: "0 2em", width: "auto", height: "3em"}}
                                />
                            </div>
                        ))}</div>
                    </div>
                </div>
            }
            {isMobileResolution &&
                <div ref={featuredRef} style={{ display: "flex", flexDirection:"column", alignItems:"center", marginTop: "10vh"}}>
                    <div ref={featuredRef} className={"featuredList"} style={{display: "flex", flexDirection: "column", alignItems:"center", justifyContent: "center", height: "6em"}}>

                        <h1 style={{margin: "0 0 .5em 0", fontSize: "1em", color: "black"}}>Featured On</h1>
                        <div style={{display: "flex", flexDirection: "row", alignItems:"center", justifyContent: "center", marginBottom: "1em"}}>
                        {featuredData && featuredData.map((featured, index) => (
                            <div key={index} >
                                <img src={urlFor(featured.image).url()}
                                     alt={featured.title}
                                     style={{padding: "0 .35em", width: "auto", height: ".8em"}}
                                />
                            </div>
                        ))}</div>
                    </div>
                </div>
            }
        </>
    );
}