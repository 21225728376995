import React,{useEffect, useState} from 'react';
import sanityClient from "../../../../../client";
import BlockContent from "@sanity/block-content-to-react";
import {useScreen} from "../../../../../hooks/useScreen";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source);
}


export function AboutCarouselItem1() {
    const isDesktopResolution = useScreen("(min-width:900px)", true);
    const isMobileResolution = useScreen("(max-width:899px)", false);

    const [aboutData1, setAboutData1] = useState(null);

    useEffect(() => {
        sanityClient.fetch(
            `*[_type == "about1"]{
        title,
        textContent,
        image{
          asset->{
          _id,
          url
        }
        }
    }`
        )
            .then((data) => setAboutData1(data))
            .catch(console.error);
    }, []);

    return(
        <>
            {isDesktopResolution && <>{aboutData1 && aboutData1.map((item, index)=>(
                <div style={{
                    width: "80%",
                    height: "30em",
                    backgroundColor: "#262422",
                    border: "white 1.2px solid",
                    borderRadius: ".4em",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "2em 1em",
                    margin: ".15em",
                }}
                     key={index}
                >
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div style={{position: "relative", left: "-1.2em", top: "-2em", zIndex: "1000" }}>
                            <button className={"btn"} style={{width: "14em"}}>Exclusive Feature</button>
                        </div>
                        <img style={{
                            width: "100%",
                            marginTop: "-1em" ,
                            borderRadius: ".4em",
                            height: "10em",
                            objectFit: "cover"
                        }}
                             src={urlFor(item.image).url()} />
                        <div style={{height: "2.6em"}}>
                            <h1 style={{marginTop: ".65em", textAlign: "center"}}>{item.title}</h1>
                        </div>
                        <div style={{fontSize: "1em", display: "flex", alignItems: "flex-end", width: "85%", paddingLeft: "1em", textAlign: "center"}}>
                            <BlockContent blocks={item.textContent}/>
                        </div>

                    </div>
                </div>
            ))}</>}
            {isMobileResolution &&<>
                {aboutData1 && aboutData1.map((item, index)=>(
                    <div style={{
                        width: "77%",
                        height: "32em",
                        backgroundColor: "#262422",
                        border: "white 1.2px solid",
                        borderRadius: ".4em",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "2em 1em",
                        margin: ".15em",
                    }}
                         key={index}
                    >
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div style={{position: "relative", left: "-1.2em", top: "-2em", zIndex: "1000" }}>
                                <button className={"btn"} style={{width: "12em"}}>Exclusive Feature</button>
                            </div>
                            <img style={{
                                width: "100%",
                                marginTop: "-1em" ,
                                borderRadius: ".4em",
                                height: "10em",
                                objectFit: "cover"
                            }}
                                 src={urlFor(item.image).url()} />
                            <div style={{height: "2.2em"}}>
                                <h1 style={{marginTop: ".25em", textAlign: "center"}}>{item.title}</h1>
                            </div>
                            <div style={{fontSize: "1em", display: "flex",textAlign: "center" ,alignItems: "flex-end",  width: "auto", padding: "0 1em"}}>
                                <BlockContent blocks={item.textContent}/>
                            </div>

                        </div>
                    </div>
                ))}
            </>
            }
        </>
    );
}