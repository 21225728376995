import React,{useEffect, useState} from 'react';
import {NextArrow} from "../../about/aboutCarousel/aboutCarouselArrows/nextArrow";
import {BackArrow} from "../../about/aboutCarousel/aboutCarouselArrows/backArrow";
import Slider from 'react-slick'
import {VideoQuote} from "../videoQuote";
import {useScreen} from "../../../../hooks/useScreen";
import {VideoQuote2} from "../videoQuote2";
import {VideoQuote3} from "../videoQuote3";




export function VideoSlider() {
    const isDesktopResolution = useScreen("(min-width:900px)", true);
    const isMobileResolution = useScreen("(max-width:899px)", false);


    const settings = {
        dots: false,
        infinite: true,

        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <NextArrow to={"next"} />,
        prevArrow: <BackArrow to={"prev"}/>,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 899,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return(
        <>
            {isDesktopResolution &&
                <div style={{width: "80%", height: "34em"}}>
                    <Slider {...settings}>
                        <VideoQuote />
                        <VideoQuote2 />
                        <VideoQuote3 />
                    </Slider>
                </div>
            }
            {isMobileResolution &&
                <div style={{width: "90%", height: "auto"}}>
                    <Slider {...settings}>
                        <VideoQuote />
                        <VideoQuote2 />
                        <VideoQuote3 />
                    </Slider>
                </div>
            }

            </>
    );
}