import React, {useEffect, useRef, useState} from 'react';
import {HeaderList} from "./headerList/headerList";
import "./header.scss";
import {useScreen} from "../../hooks/useScreen";
import {GiHamburgerMenu} from "react-icons/gi";
import {MobileHeader} from "./mobileHeader/mobileHeader";
import {IoClose} from "react-icons/io5";
import {ReactComponent as LogoB} from "../../assets/icons/logoB.svg";
import {ReactComponent as LogoBSm} from "../../assets/icons/logoBSm.svg";
import {Link} from "react-router-dom";

export function Header() {

    const isDesktopResolution = useScreen("(min-width:900px)", true);
    const isMobileResolution = useScreen("(max-width:899px)", false);

    const [openNav, setOpenNav] = useState(false);

    let modalRef = useRef();

    function checkClickOutside(e){
        if (openNav && !modalRef.current.contains(e.target)){
            setOpenNav(!openNav)
        }
    }

    useEffect(()=>{
        document.addEventListener('mousedown', checkClickOutside)
        return()=> document.removeEventListener('mousedown', checkClickOutside)
    }, [openNav])


    return(
        <>
            {isDesktopResolution &&
                <div className={"nav"}>
                    <div style={{position: "absolute", zIndex: "1000", top: "1em", left: "2em"}}>
                        <Link to={"/"}>
                            <LogoB/>
                        </Link>
                    </div>
                    <HeaderList />
                </div>
            }
            {isMobileResolution && <>
                <div className={"navMobile"} style={{display: "flex", width: "100vw", alignItems: "center" ,justifyContent: "flex-end"}}>
                    <div style={{position: "absolute", top: "1em", left: "41vw"}}>
                        <Link to={"/"}>
                            <LogoBSm />
                        </Link>
                    </div>
                    <div onClick={() => {
                        setOpenNav(!openNav);
                    }}>
                        {openNav ? <IoClose style={{paddingRight: "1em"}} size={28}/> : <GiHamburgerMenu style={{marginRight: "1em"}} size={22}/>}
                    </div>
                </div>
                {openNav && <div ref={modalRef} className={"menu"}><MobileHeader/></div>}
            </>
            }
        </>
    );


}
