import React, {useState, useEffect} from 'react';
import {useScreen} from "../../../hooks/useScreen";
import sanityClient from "../../../client";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
import {Link} from "react-router-dom";
import {AboutCarousel} from "./aboutCarousel/aboutCarousel";
import {Break} from "../break/break";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source);
}


export function About() {
    const [isOpen, setIsOpen] = useState()
    const isDesktopResolution = useScreen("(min-width:900px)", true);
    const isMobileResolution = useScreen("(max-width:899px)", false);

    const [imageBoxData, setImageBoxData] = useState(null);

    useEffect(() => {
        sanityClient.fetch(
            `*[_type == "aboutSection"]{
        title,
        textContent,
        slug,
        button,
        image{
          asset->{
          _id,
          url
        }
      }
    }`
        )
            .then((data) => setImageBoxData(data))
            .catch(console.error);
    }, []);

    return(
        <>
            {isDesktopResolution &&
                <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {imageBoxData && imageBoxData.map((ImageBox, index)=>(
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}} key={index}>
                            <h1 style={{fontSize: "2em"}}>{ImageBox.title}</h1>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", margin:"1.5em 0",}}>
                                <div style={{width: "45%"}}>
                                    <BlockContent blocks={ImageBox.textContent} />
                                </div>
                                <img src={urlFor(ImageBox.image).url()}
                                     style={{
                                         margin:"1.5em 0",
                                         width: "50%",
                                         height: "30em",
                                         objectFit: "cover",
                                         border: "white 1.2px solid",
                                         borderRadius: ".4em"}}/>
                            </div>
                        </div>
                    ))}
                    <h1 style={{fontSize: "2em"}}>This Will Be Achieved Through</h1>
                    <Break/>

                </div>
            }
            {isMobileResolution &&
                <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {imageBoxData && imageBoxData.map((ImageBox, index)=>(
                        <>
                    <h1 style={{textAlign: "center"}}>{ImageBox.title}</h1>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", fontSize: "1.1em", textAlign: "center"}}>
                        <img src={urlFor(ImageBox.image).url()}
                             style={{
                                 margin:"1.5em 0",
                                 width: "100%",
                                 height: "auto",
                                 objectFit: "contain",
                                 border: "white 1.2px solid",
                                 borderRadius: ".4em",
                        }}
                        />
                        <BlockContent blocks={ImageBox.textContent} />
                    </div>
                            <Break />
                        </> ))}
                    <h1 style={{fontSize: "1.44em", textAlign: "center"}}>This Will Be Achieved Through</h1>
                    <Break/>

                </div>

            }
        </>
    );
}