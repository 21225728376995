import React, {useEffect, useState} from 'react';
import {AboutCarouselItem1} from "./aboutCarouselItems/aboutCarouselItem1";
import {AboutCarouselItem2} from "./aboutCarouselItems/aboutCarouselItem2";
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {AboutCarouselItem3} from "./aboutCarouselItems/aboutCarouselItem3";
import {AboutCarouselItem4} from "./aboutCarouselItems/aboutCarouselItem4";
import {AboutCarouselItem5} from "./aboutCarouselItems/aboutCarouselItem5";
import {AboutCarouselItem6} from "./aboutCarouselItems/aboutCarouselItem6";
import {NextArrow} from "./aboutCarouselArrows/nextArrow";
import {BackArrow} from "./aboutCarouselArrows/backArrow";
import {useScreen} from "../../../../hooks/useScreen";


export function AboutCarousel() {
    const isDesktopResolution = useScreen("(min-width:900px)", true);
    const isMobileResolution = useScreen("(max-width:899px)", false);

    const settings = {
        dots: false,
        infinite: true,
        centerMode: true,
        centerPadding: "-1px",
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <NextArrow to={"next"} />,
        prevArrow: <BackArrow to={"prev"}/>,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 899,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return(
        <>
            {isDesktopResolution &&
                <div style={{width: "100%", display: "block"}}>
                    <Slider {...settings}>
                        <AboutCarouselItem1/>
                        <AboutCarouselItem2/>
                        <AboutCarouselItem3/>
                        <AboutCarouselItem4/>
                        <AboutCarouselItem5/>
                        <AboutCarouselItem6/>
                    </Slider>
                </div>
            }
            {isMobileResolution &&
                <div style={{width: "100%", display: "block", fontSize: "1.1em"}}>
                    <Slider {...settings}>
                        <AboutCarouselItem1/>
                        <AboutCarouselItem2/>
                        <AboutCarouselItem3/>
                        <AboutCarouselItem4/>
                        <AboutCarouselItem5/>
                        <AboutCarouselItem6/>
                    </Slider>
                </div>
            }
        </>
    );
}