import {Theme} from "./theme/theme";
import {Header} from "./components/header/header";
import {Home} from "./pages/home/home";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {BookingPage} from "./pages/bookingPage/bookingPage";
import {Faq} from "./pages/faq/faq";
import {AboutPage} from "./pages/about/aboutPage";
import {Footer} from "./components/footer/footer";
import 'react-multi-carousel/lib/styles.css';
import ReactGA from "react-ga4";
import {ConfirmedBooking} from "./pages/bookingPage/confirmed/confirmedBooking";



export function App() {
    ReactGA.initialize("G-H9PGH6TNHP");

  return (
      <Router>
              <Theme>
                  <Routes>
                      <Route path={'/'} element={<Home/>}/>
                      <Route path={'faq'} element={<Faq/>}/>
                      <Route path={'/booking'} element={<BookingPage/>}/>
                      <Route path={'/about'} element={<AboutPage/>}/>
                      <Route path={'/booking/confirmed'} element={<ConfirmedBooking/>}/>
              </Routes>
              </Theme>
          <Footer/>
      </Router>

  );
}

