import React from 'react';
import {IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin, IoLogoYoutube} from "react-icons/io";
import {HiOutlineMail} from "react-icons/hi";
import {useScreen} from "../../hooks/useScreen";
import {Link} from "react-router-dom";
import {UseMail} from "../../hooks/useMail";
import ReactGA from "react-ga4";


export function Footer() {
    const isDesktopResolution = useScreen("(min-width:900px)", true);
    const isMobileResolution = useScreen("(max-width:899px)", false);

    const handleClick = (platform) => {
        ReactGA.event({
            category: 'Booking Link',
            action: 'Click',
            label: platform,
        });
    };

    return(
        <>
            {isDesktopResolution &&
                <div className={"footer"} style={{padding: "1.5em 2em 0em 2em", display: "flex", justifyContent: "center",  flexDirection: "column", alignItems: "center"}}>
                    <h2>Contact</h2>
                    <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", alignContent: "center", margin: "1em 1em 0 1em"}}>
                        <Link
                            onClick={()=>handleClick("Facebook")}
                            style={{color: "black"}}
                            to={"https://www.facebook.com/richard.janson.7923"}
                            target="_blank" >
                            <IoLogoFacebook size={26} style={{margin: "0 .5em"}} />
                        </Link>
                        <Link
                            onClick={()=>handleClick("Instagram")}
                            style={{color: "black"}}
                            to={"https://www.instagram.com/richardjansoncoaching?igsh=eWtuanNkZWN2eWFt"}
                            target="_blank">
                            <IoLogoInstagram size={26} style={{margin: "0 .5em"}} />
                        </Link>
                        <Link
                            onClick={()=>handleClick("Linkedin")}
                            style={{color: "black"}}
                            to={"https://www.linkedin.com/in/richard-janson-259826197?utm_source=share&utm_campaign=share_via&utm_content=profile"}
                            target="_blank">
                            <IoLogoLinkedin size={26} style={{margin: "0 .5em"}} />
                        </Link>

                        <UseMail
                            email="richard@jansoncoaching.com"
                        >
                            <HiOutlineMail
                                onClick={()=>handleClick("Email")}
                                color={"black"} size={26} style={{margin: "0 .5em"}} />
                        </UseMail>

                        <Link
                            onClick={()=>handleClick("Youtube")}
                            style={{color: "black"}}
                            to={"https://www.youtube.com/@richardjansondating"}
                            target="_blank"><IoLogoYoutube size={26} style={{margin: "0 .5em"}} />
                        </Link>
                        <Link
                            onClick={()=>handleClick("/faq")}
                            style={{color: "black", textDecoration: "none"}}
                            to={"/faq"}><h4 size={26} style={{margin: "0 .5em", fontSize: "1.3em"}}>FAQ</h4></Link>
                    </div>
                    <Link to={"/booking"} style={{color: "black", textDecoration: "none"}}onClick={()=>handleClick("/booking")}><h4>Book a Free Strategy Call</h4></Link>
                    <p>JansonCoaching, 2024©</p>
                </div>
            }
            {isMobileResolution &&
                <div className={"footer"} style={{padding: "3em 2em", display: "flex", justifyContent: "center",  flexDirection: "column", alignItems: "center"}}>
                    <h2>Contact</h2>
                    <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", alignContent: "center", margin: "1em 1em"}}>
                        <Link
                            onClick={()=>handleClick("Facebook")}
                            style={{color: "black"}} to={"https://www.facebook.com/richard.janson.7923"} target="_blank" ><IoLogoFacebook size={32} style={{margin: "0 .5em"}} /></Link>
                        <Link
                            onClick={()=>handleClick("Instagram")}
                            style={{color: "black"}} to={"https://www.instagram.com/richardjansoncoaching?igsh=eWtuanNkZWN2eWFt"} target="_blank"><IoLogoInstagram size={32} style={{margin: "0 .5em"}} /></Link>
                        <Link
                            onClick={()=>handleClick("Linkedin")}
                            style={{color: "black"}} to={"https://www.linkedin.com/in/richard-janson-259826197?utm_source=share&utm_campaign=share_via&utm_content=profile"} target="_blank"><IoLogoLinkedin size={32} style={{margin: "0 .5em"}} /></Link>
                       <UseMail
                           email="richard@jansoncoaching.com"
                       >
                            <HiOutlineMail onClick={()=>handleClick("Email")} color={"black"} size={32} style={{margin: "0 .5em"}} />
                       </UseMail>
                        <Link
                            onClick={()=>handleClick("Youtube")}
                            style={{color: "black"}} to={"https://www.youtube.com/@richardjansondating"} target="_blank"><IoLogoYoutube size={32} style={{margin: "0 .5em"}} /></Link>
                        <Link
                            onClick={()=>handleClick("/faq")}
                            style={{color: "black", textDecoration: "none"}} to={"/faq"}><h4 size={32} style={{margin: "0 .5em", fontSize: "1.65em"}}>FAQ</h4></Link>
                    </div>
                    <Link to={"/booking"} onClick={()=>handleClick("/booking")} style={{color: "black", textDecoration: "none"}}><h4>Book a Free Strategy Call</h4></Link>
                    <p>JansonCoaching, 2024©</p>
                </div>
            }
        </>
    );
}