import React, {useEffect} from 'react';
import {Break} from "../../components/sections/break/break";
import {About} from "../../components/sections/about/about";
import {Header} from "../../components/header/header";
import {AboutCarousel} from "../../components/sections/about/aboutCarousel/aboutCarousel";
import {Link} from "react-router-dom";
import {useScreen} from "../../hooks/useScreen";
import ReactGA from "react-ga4";

ReactGA.send({ hitType: "pageview", page: "/about", title: "About us" });

export function AboutPage() {
    const isDesktopResolution = useScreen("(min-width:900px)", true);
    const isMobileResolution = useScreen("(max-width:899px)", false);

    const handleClick = (platform) => {
        ReactGA.event({
            category: 'Booking Link',
            action: 'Click',
            label: platform,
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <>
        <Header/>
            <Break/>
            <Break/>
            <About/>
            <AboutCarousel/>
            <Break/>
            {isDesktopResolution &&
                <Link to={"/booking"} onClick={()=>handleClick("/booking")}><button className={"btn"} style={{width: "20em", margin:"1.5em 0", alignItems: "center"}}>Book a Free Strategy Call</button></Link>
            }
            {isMobileResolution &&
                <Link to={"/booking"} onClick={()=>handleClick("/booking")}><button className={"btn"} style={{width: "14em", margin:"1.5em 0", fontSize: "1.3em"}}>Book A Free Strategy Call</button></Link>
            }
            <Break/>
            <Break/>
        </>
    );
}