import React, {useEffect, useState} from 'react';
import {Break} from "../../components/sections/break/break";
import sanityClient from "../../client";
import BlockContent from "@sanity/block-content-to-react";
import {Header} from "../../components/header/header";
import ReactGA from "react-ga4";

ReactGA.send({ hitType: "pageview", page: "/faq", title: "FAQ" });

export function Faq() {

    const [pageData, setPageData] = useState(null);

    useEffect(() => {
        sanityClient.fetch(
            `*[_type == "faqPage"]{
        title,
        textContent,
        slug,
        
    }`
        )
            .then((data) => setPageData(data))
            .catch(console.error);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <>
            <Header/>
            <Break/>
            <div style={{width: "80%", fontSize: "1.1em"}}>
            <Break/>
            {pageData && pageData.map((item, index)=>(
                <div key={index}>
                    <BlockContent blocks={item.textContent}/>
                </div>
            ))}
        </div>
        </>
    );
}