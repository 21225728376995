import React, {useEffect, useState}  from 'react';
import sanityClient from "../../../client";
import ReactPlayer from "react-player";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import {useScreen} from "../../../hooks/useScreen";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source);
}


export function VideoQuote2() {
    const isDesktopResolution = useScreen("(min-width:900px)", true);
    const isMobileResolution = useScreen("(max-width:899px)", false);
    const [videoData, setVideoData] = useState(null);

    useEffect(() => {
        sanityClient.fetch(
            `*[_type == "vidTestimonial2"]{
        title,
        textContent,
        image{
          asset->{
          _id,
          url
        }
      }
    }`
        )
            .then((data) => setVideoData(data))
            .catch(console.error);
    }, []);

    return(
        <>
            {isDesktopResolution &&
                <div style={{width: "96%", height: "auto", objectFit: "cover" ,border: "white 1.2px solid" }}>
                    {videoData && videoData.map((item, index)=>(
                        <div key={index}>
                            <div style={{padding: "0 2em",position: "fixed", fontSize: "120%", bottom: "1em", zIndex: "1000"}}>
                                <BlockContent blocks={item.textContent}/>
                            </div>
                            <div style={{width: "70%", height: "6em", background: "black", opacity: "20%", zIndex: "100",position: "fixed", bottom: "0"}}/>
                            <ReactPlayer
                                url={item.title}
                                controls
                                light={urlFor(item.image).url()}
                                height={"30em"}
                                width={"100%"}
                            />
                        </div>
                    ))}

                </div>
            }
            {isMobileResolution &&
                <div style={{width: "93%", height: "auto", objectFit: "contain" }}>
                    {videoData && videoData.map((item, index)=>(
                        <div key={index}>
                            <div style={{padding: "0 1em",position: "fixed", fontSize: "100%", bottom: "1em", zIndex: "1000"}}>
                            </div>
                            <ReactPlayer
                                url={item.title}
                                controls
                                light={urlFor(item.image).url()}
                                height={"10em"}
                                width={"auto"}
                            />
                        </div>
                    ))}

                </div>
            }
        </>
    );
}