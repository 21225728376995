import React, {useEffect} from 'react';
import {Break} from "../../components/sections/break/break";
import {Video} from "../../components/sections/video/video";
import {Featured} from "../../components/sections/featured/featured";
import {ImageBox} from "../../components/sections/imageBox/imageBox";
import {About} from "../../components/sections/about/about";
import {SkillsetBlock} from "../../components/sections/SkillsetBlock/skillsetBlock";
import {Header} from "../../components/header/header";
import {AboutCarousel} from "../../components/sections/about/aboutCarousel/aboutCarousel";
import {Link} from "react-router-dom";
import {TestimonialSlider} from "../../components/sections/testimonial/testimonialSlider";
import {useScreen} from "../../hooks/useScreen";
import {VideoSlider} from "../../components/sections/videoTestimonial/videoSlider/videoSlider";
import {Booking} from "../../components/sections/booking/booking";
import ReactGA from "react-ga4";

ReactGA.send({ hitType: "pageview", page: "/", title: "Homepage" });

export function Home() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const handleClick = (platform) => {
        ReactGA.event({
            category: 'Booking Link',
            action: 'Click',
            label: platform,
        });
    };

    const isDesktopResolution = useScreen("(min-width:1200px)", true);
    const isMobileResolution = useScreen("(max-width:1199px)", false);

        return(
            <>
                <Header/>
                <Break/>
                <Break/>
                <Break/>
                <Video />
                <Break/>
                <Break/>
                <Featured />
                <Break/>
                <Break/>
                {isDesktopResolution &&
                    <div style={{width: "100vw", background: "#262422", display: "flex", justifyContent: "center", height: "40vw"}}>
                        <div style={{width: "60%", padding: "2em 0", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <ImageBox/>
                            <Break/>
                        </div>
                    </div>
                }
                {isMobileResolution &&
                    <div style={{width: "100vw", background: "#262422", display: "flex", justifyContent: "center", height: "59em"}}>
                        <div style={{width: "70%", padding: "2em 0", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <ImageBox/>
                        </div>
                    </div>
                }
                <Break/>
                {isDesktopResolution &&
                    <>
                        <h1 style={{margin: " 0 0 1.5em 0", fontSize: "2em" ,textAlign: "center"}}>What Our Clients Say</h1>
                        <VideoSlider/>
                    </>
                }
                {isMobileResolution &&
                    <>
                        <h1 style={{margin: " 0 0 1.5em 0", textAlign: "center"}}>What Our Clients Say</h1>
                        <VideoSlider/>
                        <Break/>
                        <Break/>
                    </>
                }
                {isDesktopResolution &&
                    <div style={{width: "100vw", background: "#262422", display: "flex", justifyContent: "center", height: "auto"}}>
                        <div style={{width: "70%"}}>

                            <Break/>
                            <TestimonialSlider/><Break/>
                        </div>

                    </div>
                }
                {isMobileResolution &&
                    <div style={{width: "100vw", background: "#262422", display: "flex", justifyContent: "center", padding: "2em 0", height: "50em"}}>
                        <div style={{width: "90%"}}>
                            <TestimonialSlider/>
                        </div>
                    </div>
                }
                <Break/>
                <Break/>
                <About/>
                <AboutCarousel/>
                {isDesktopResolution &&
                    <Link to={"/booking"} onClick={()=>handleClick("/booking")}><button className={"btn"} style={{width: "20em", margin:"1.5em 0", alignItems: "center"}}>Book a Free Strategy Call</button></Link>
                }
                {isMobileResolution &&
                    <>
                    <Break/>
                    <Link to={"/booking"} onClick={()=>handleClick("/booking")}><button className={"btn"} style={{width: "14em", margin:"1.5em 0 1.5em 0", fontSize: "1.3em"}}>Book A Free Strategy Call</button></Link>
                    </>
                }
                <Break/>
                <Break/>
                {isDesktopResolution &&
                    <div style={{width: "100vw", background: "#262422", display: "flex", justifyContent: "center", padding: "2em 0"}}>
                        <div style={{width: "70%",}}>
                            <SkillsetBlock/>
                        </div>
                    </div>
                }
                {isMobileResolution &&
                    <div style={{width: "100vw", background: "#262422", display: "flex", justifyContent: "center", padding: "2em 0"}}>
                        <div style={{width: "100%",}}>
                            <SkillsetBlock/>
                        </div>
                    </div>
                }
                <Break/>
                <Booking/>
                <Break/>
            </>
    );
}