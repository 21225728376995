import React, {useEffect, useRef, useState} from 'react';
import {useFadeInOnScroll} from "../../../hooks/useFadeInOnScroll";
import {useScreen} from "../../../hooks/useScreen";
import sanityClient from "../../../client";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
import {Link} from "react-router-dom";
import ReactGA from "react-ga4";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source);
}

export function ImageBox() {

    const handleClick = (platform) => {
        ReactGA.event({
            category: 'Booking Link',
            action: 'Click',
            label: platform,
        });
    };

    const isDesktopResolution = useScreen("(min-width:1200px)", true);
    const isMobileResolution = useScreen("(max-width:1199px)", false);

    const [imageBoxData, setImageBoxData] = useState(null);

    useEffect(() => {
        sanityClient.fetch(
                `*[_type == "ImageBox"]{
        title,
        textContent,
        slug,
        button,
        image{
          asset->{
          _id,
          url
        }
      }
    }`
            )
            .then((data) => setImageBoxData(data))
            .catch(console.error);
    }, []);

    const imageRef = useRef(null);
    useFadeInOnScroll(imageRef);

    return(
        <div ref={imageRef} style={{width:"100%"}}>
            {isDesktopResolution &&
                <div style={{width:"100%", display: "flex", flexDirection: "column", alignContent:"stretch"}}>
                        {imageBoxData && imageBoxData.map((ImageBox, index) =>(
                            <div style={{display: "flex"}}>
                            <div
                                key={index}
                                style={{display: "flex", flexDirection: "row", justifyContent:"center"}}>
                                <img src={urlFor(ImageBox.image).url()}
                                     alt=""
                                     style={{
                                         width: "100%",
                                         height:"28em",
                                         objectFit: "cover",
                                         border: "white 1.2px solid",
                                         borderRadius: ".4em"
                                }}/>
                                <div style={{width: "100%",display: "flex", flexDirection:"column", marginLeft: "4em", alignItems: "center", justifyContent: "space-evenly", height: "20em"}}>
                                    <h1 style={{fontSize: "2em", textAlign: "center"}}>{ImageBox.title}</h1>
                                    <div style={{fontSize: "1.1em", lineHeight: "1.25em"}}>
                                        <BlockContent blocks={ImageBox.textContent}/>
                                    </div>
                                    <Link to={"/booking"} onClick={()=>handleClick("/booking")}><button className={"btn"} style={{width: "14em", fontSize: "1em"}}>I'm Ready</button></Link>
                                </div>
                            </div>
                            </div> ))}
                </div>
            }
            {isMobileResolution &&
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                            {imageBoxData && imageBoxData.map((ImageBox, index) => (
                                <div key={index}>
                                    <h1 style={{textAlign: "center"}}>{ImageBox.title}</h1>
                                    <br/>
                                    <img src={urlFor(ImageBox.image).url()}
                                         alt=""
                                         style={{
                                             width: "100%",
                                             height: "20em",
                                             objectFit: "cover",
                                             border: "white 1.2px solid",
                                             borderRadius: ".4em"
                                         }}/>
                                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                        <div style={{fontSize: "1.1em", marginBottom: "1em", lineHeight: "1.5em"}}>
                                            <BlockContent blocks={ImageBox.textContent}/>
                                        </div>
                                        <Link to={"/booking"} onClick={()=>handleClick("/booking")}><button className={"btn"} style={{width: "14em", fontSize: "1.3em"}}>{ImageBox.button}</button></Link>
                                    </div>
                                </div>
                            ))}
                        </div>
            }
        </div>
    );
}